#clockdiv{
	color: #fff;
	display: inline-block;
	font-weight: 100;
	text-align: center;
	font-size: 30px;
	position: absolute;
	top: 160px;
	right: 106px;
}

#clockdiv > div{
	padding: 0px;
	display: inline-block;
	color: #333333;
}

#clockdiv div > span{
	text-align: center;
	width: 82px;
	height: 90px;
	padding-top: 25px;
	background-image:url('../img/errors/clock.gif');
	background-size: 100% 100%;
	display: inline-block;
}

.smalltext{
	font-family: 'Dangrek', Arial, Helvetica, sans-serif;
	padding-top: 5px;
	font-size: 20px;
	color: #999999;
}