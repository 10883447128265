
#menu {
	background: #9E0910;
	color: #BDB9A6;
	border: 0;
	margin-bottom: 15px;
	border: 0;
	margin: 0;
}
nav#menu .nav {
	width: 100%;
}
#menu #search {
	position: absolute;
	top: 0;
	right: 0;
	padding-right: 15px;
	height: 50px;
	background: transparent;
	/*padding-left: 15px;*/
	height: 100%;
}
#menu #search form {
	position: relative;
	height: 100%;
}
#menu #search form button {
	position: absolute;
	top: 0px;
	right: 0px;
	color: rgba(255, 255, 255, 0.8);
	width: 40px;
	height: 100%;
	border: 0;
	background: transparent;
	text-align: center;
	/*line-height: 28px;*/
	padding: 0;
	font-size: 18px;
}
#menu #search form button.left_submit {
	left: 0;
	display: none;
}
#menu #search form a {
	float: right;
	color: #ffffff;
	font-size: 16px;
	margin-left: 10px;
	padding-top: 12px;
	height: 100%;
}
#menu #search input{
	width: 0;
	height: 100%;
	padding: 0;
	line-height: 30px;
	background: #6F060B;
	color: #ffffff;
	border: 0;
}
#menu #search input:focus{
	background: #6F060B;
}
.font-strong {
	font-family: 'Dangrek', Arial, Helvetica, sans-serif;
}
#menu.navbar-inverse .navbar-nav > li > a {
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Dangrek', Arial, Helvetica, sans-serif;
  font-size: 20px;
  position: relative;
}
#menu.navbar-inverse .navbar-nav > li > a > img {
  height: 50px;
  margin-top: -21px;
  margin-bottom: -20px;
}
#menu.navbar-inverse .navbar-nav > li > a:hover{
  color: #ffffff;
  @include transition(.3s);
}

#menu.navbar-inverse .navbar-nav > li.active > a {
  background: #CC2C7B;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

nav#menu .nav > li.more {
	display: none;
}

nav#menu .nav > li > .dropdown-menu {
    z-index: 9999;
	background-color: #f103ae;
}
nav#menu.navbar #overflow a {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Dangrek', Arial, Helvetica, sans-serif;
    font-size: 20px;
    position: relative;
}
.dropdown-menu li > a {
	padding-top: 14px;
    padding-bottom: 14px;
}
#menu.navbar #overflow li {
	border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #fff;
}
nav#menu.navbar #overflow li:last-child {
    border-width: 0;
}
.navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
	background-color: #d40499;
}
nav#menu.navbar #overflow.dropdown-menu {
	padding: 0px 0;
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
	background: #CC2C7B;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

#menu.sub-menu {
	background-color: #ffffff;
	top: 50px;
	position: sticky;
}
#menu.navbar-inverse .sub-nav>li.active {
	border-bottom:3px solid #CC2C7B;
}
#menu.navbar-inverse .sub-nav > li.active > a {
	background: none;
	text-shadow: none;
}
#menu.sub-menu .sub-nav > li a, #menu.sub-menu .sub-nav > li a:hover{
	color: #443f3fed;
}

.sub-menu .nbs-flexisel-item {
	padding: 0px;
	cursor: pointer;
	position: relative;
	line-height: 22px;
	padding-top: 14px;
	padding-bottom: 14px;
}
.sub-menu li {
	display:inline-block;
	font-family: 'Dangrek', Arial, Helvetica, sans-serif;
	font-size: 20px;
}
.sub-menu .nbs-flexisel-item a {
	font-family: 'Dangrek', Arial, Helvetica, sans-serif;
	font-size: 20px;
	color: #443f3fed;
	padding: 10px 15px;
}
.sub-menu .nbs-flexisel-item a:focus, .sub-menu .nbs-flexisel-item a:hover {
	text-decoration: none;
}
.sub-menu .nbs-flexisel-inner {
	background-color: #ffffff;
}
.sub-menu .nbs-flexisel-nav-left{
	left: -5%;
    margin-top: 10px;
}
.sub-menu .nbs-flexisel-nav-left:before {
    content: '';
    position: absolute;
    top: 9px;
	right: 7px;
    border-top: 10px solid #a7adb9;
    border-left: 10px solid transparent;
    transform: rotate(225deg);
}
.sub-menu .nbs-flexisel-nav-right{
	right: -5%;
	margin-top: 10px;
}
.sub-menu .nbs-flexisel-nav-right:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 7px;
    border-top: 10px solid #a7adb9;
    border-left: 10px solid transparent;
    transform: rotate(45deg);
}
.sub-menu .nbs-flexisel-nav-left, .sub-menu .nbs-flexisel-nav-right {
	border:1px solid #dce2f099;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	top: 20%;
	background-image: none;
}
#menu.navbar-inverse .sub-nav#sub_menu_slider > li.active {
	border-bottom: 3px solid #CC2C7B;
}

.sub-nav::-webkit-scrollbar {
	-webkit-appearance: none;
	height: 0;
}

@media (max-width: 767px) {
	#menu {
		margin: 0;
		background: #161616;
	}
	nav#menu .nav {
		width: auto;
	}
	#menu .container {
		padding: 0 15px;
	}
	#menu #search {
		background: #161616;
		height: 50px;
	}
	#menu #search input{
		width: 0px;
		padding: 0;
	}
	#menu #search form button {
		color: #ffffff;
		cursor: pointer;
	}
	.mean-content {
		margin-top: 0px;
	}
	.navbar-nav {
		margin: 0;
		margin-left: -15px;
		margin-right: -15px;
		padding: 0;
	}
	#menu .nav.navbar-nav li a img{
		width:auto;
		height:40px;
	}
	#menu.navbar-inverse .sub-nav#sub_menu_slider > li:first-child > a {
		padding-left: 0px;
	}
	.sub-menu .nbs-flexisel-nav-right{
		right: -30px;
	}
	.sub-menu .nbs-flexisel-nav-left{
		left: -30px;
	}
}