/* khmer */
@font-face {
  font-family: 'Battambang';
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: local('Battambang'), url(../fonts/battambang.woff2) format('woff2');
  unicode-range: U+1780-17FF, U+200B-200C, U+25CC;
}
/* khmer */
@font-face {
  font-family: 'Dangrek';
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: local('Dangrek'), url(../fonts/dangrek.woff2) format('woff2');
  unicode-range: U+1780-17FF, U+200B-200C, U+25CC;
}